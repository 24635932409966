<!--  联盟架构 -->
<template>
  <div class="alliancearchitecture" style=" background: linear-gradient(to bottom, #fafafa,#dedede);">
    <el-image
        v-if="this.language === 'zh_CN'"
        :src="require('@/assets/images/aboutus/jiagou.png')"
        fit="contain" />
    <el-image
        v-if="this.language !== 'zh_CN'"
        :src="require('@/assets/images/aboutus/jiagourus.png')"
        fit="contain" />
  </div>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "AllianceArchitecture",
    computed:{
      ...mapGetters([
        'language'
      ])
    }
  }
</script>

<style scoped lang="scss">
</style>

