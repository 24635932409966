import { render, staticRenderFns } from "./alliance-architecture.vue?vue&type=template&id=0d9dab66&scoped=true"
import script from "./alliance-architecture.vue?vue&type=script&lang=js"
export * from "./alliance-architecture.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d9dab66",
  null
  
)

export default component.exports